<template>
  <div class="header">
    <div class="container">
      <div @click="isBurger = !isBurger" class="burger">| | |</div>
      <router-link to="/">
        <img src="../../assets/img/header/logo.png" alt="">
      </router-link>
      <div :class="{'_active': isBurger}" class="header-wrap">
        <div class="header__container">Выигранные дела</div>
        <div class="header__container">Ходатайства бесплатно</div>
        <div class="header__container">Контакты</div>
        <div class="header__container">Видеоруководство бесплатно</div>
      </div>
    </div>
    <div id="modal" style="display: none;max-height: initial" class="modal">
      <div class="modal-wrapper">
        <div class="modal-title">
          Получите консультацию и персональные условия
        </div>
        <div class="modal-subtitle">
          Наш юрист поможет с выбором варианта решения и ответит на все вопросы
        </div>
        <form>
          <div class="form-wrapper">
            <label>
              <span>Ваше имя</span>
              <input type="text" v-model="name" placeholder="Введите имя">
            </label>
            <label>
              <span>E-mail</span>
              <input type="email" v-model="email" placeholder="E-mail">
            </label>
            <label>
              <span>Телефон</span>
              <input type="text" class="new-input" required placeholder="Ваш телефон" data-inputmask="'mask': '+7(999)999-99-99'"  v-model="phone">
            </label>
            <img src="../../assets/img/header/close_tabs.svg" alt="">
          </div>
        </form>
        <button type="submit" @click="closeTabsOutMessUrist">Получить бесплатную консультацию</button>
        <img style="display: none" id="closeTabsWait" src="../../assets/img/header/wait.gif" alt="">
        <span class="modal-btn-span">Все данные защищены</span>
      </div>
    </div>
    <div id="modalSubscribe" style="display: none;max-height: initial" class="modal">
      <div class="modal-wrapper">
        <a id="closeSocPopup" @click="removePopupSoc"><img src="../../assets/img/header/closePopupSoc.svg" alt=""></a>
        <div class="title">
          Спасибо!
          <p>
            Мы свяжемся с Вами и ответим на все Ваши вопросы. <br><br>
            Выберите удобный способ для связи:
          </p>
        </div>
        <div class="image-social_wrapper">
          <a onclick="window.open('https://wa.me/79250194968?text=Начать', '_blank', 'toolbar=1, scrollbars=1, resizable=1, width=' + 1015 + ', height=' + 800);"><img src="../../assets/img/header/soc/wa.jpg" alt=""></a>
          <a onclick="window.open('tg://resolve?domain=Avtourist_bot', '_blank', 'toolbar=1, scrollbars=1, resizable=1, width=' + 1015 + ', height=' + 800);"><img src="../../assets/img/header/soc/tg.jpg" alt=""></a>
          <a onclick="window.open('viber://pa?chatURI=avtourist-bot&context=welcome', '_blank', 'toolbar=1, scrollbars=1, resizable=1, width=' + 1015 + ', height=' + 800);"><img src="../../assets/img/header/soc/vb.jpg" alt=""></a>
          <a onclick="window.open('https://vk.com/write-7375007', '_blank', 'toolbar=1, scrollbars=1, resizable=1, width=' + 1015 + ', height=' + 800);"><img src="../../assets/img/header/soc/vk.jpg" alt=""></a>
        </div>
        <div style="text-align: center; margin-top: 15px; font-size: 12px">На основе ваших ответов мы прислали вам результаты проверки информации по базе судебных актов. Смотрите ниже ↓</div>
      </div>
    </div>
  </div>
</template>

<script>
import Inputmask from 'inputmask'
import axios from 'axios'
export default {
  name: 'Header',
  data () {
    return {
      isBurger: false,
      name: null,
      email: null,
      phone: null
    }
  },
  created () {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  },
  methods: {
    closeTabsOutMessUrist (e) {
      if (!this.name) alert('Вам необходимо указать как вас зовут')
      else if (!this.phone && !this.email) alert('Вам необходимо указать email или телефон')
      else {
        e.target.innerText = 'Ждите..'
        document.getElementById('closeTabsWait').style.display = 'block'
        const formData = new FormData()
        formData.append('crm', '11')
        formData.append('pipe', '22')
        formData.append('contact[name]', this.name)
        formData.append('contact[199]', this.phone)
        formData.append('contact[200]', this.email)
        formData.append('lead[214]', 'ЕБСА')
        formData.append('note', 'Окно выхода на странице ' + this.numArticle)

        axios.post('https://urist.v-avtoservice.com/api/import-lead',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(res => {
          e.target.innerText = 'Заявка отправлена!'
          document.getElementById('modal').remove()
          document.getElementById('modalSubscribe').style.display = 'block'
        })
      }
    },
    removePopupSoc (e) {
      document.getElementById('modalSubscribe').remove()
    }
  },
  mounted () {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  }
}
</script>

<style scoped lang="scss">
  .header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    z-index: 10;
    width: 100%;
    .container {
      width: 100%;
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .burger {
        color: white;
        transform: rotate(90deg);
        cursor: pointer;
        display: none;
        z-index: 101;
        @media (max-width: 1000px) {
          display: block;
        }
      }
      .header-wrap {
        display: flex;
        align-items: center;
        float: right;
        color: white;
        font-weight: 600;
        max-width: 100%;
        @media (max-width: 1000px) {
          position: absolute;
          background: rgba(0,0,0, 0.7);
          width: 100%;
          left: 0;
          top: 0;
          height: 60vh;
          padding-top: 100px;
          flex-direction: column;
          align-items: initial;
          display: none;
          transition: all 0.5s;
        }
        .header__container {
          margin-right: 20px;
          z-index: 20;
          @media (max-width: 1000px) {
            max-width: 100%;
            padding: 20px 30px;
            cursor: pointer;
            margin-right: initial;
            &:hover {
              background: rgba(255,255,255,0.2);
            }
          }
        }
        &._active {
          display: flex;
        }
      }
    }
    .modal-wrapper {
      #closeSocPopup {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .title {
        text-align: center;
        color: #595959;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 10px;
      }
      .image-social_wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        a {
          margin: 3px;
        }
      }
    }
  }
</style>
