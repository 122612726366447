<template>
  <div style="display: none" class="footer">asdasd</div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
  .footer {
    margin-top: 40px;
    padding: 20px 50px;
    min-height: 50px;
    background: #2A382E;
  }
</style>
