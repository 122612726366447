<template>
  <div class="v-services">
    <div class="container">
      <div class="v-services_articles">
        <h2 v-if="numArticle === '12.26'">ОТКАЗ ОТ МЕДИЦИНСКОГО ОСВИДЕТЕЛЬСТВОВАНИЯ</h2>
        <h2 v-if="numArticle === '12.27'">ОСТАВЛЕНИЕ МЕСТА ДТП</h2>
        <h2 v-if="numArticle === '12.8'">УПРАВЛЕНИЕ В СОСТОЯНИИ ОПЬЯНЕНИЯ</h2>
        <h2 v-if="numArticle === '12.15.4(5)'">ВСТРЕЧКА</h2>
        <h2 v-if="numArticle === '12.2'">УПРАВЛЕНИЕ БЕЗ НОМЕРОВ И СКРЫТИЕ НОМЕРОВ</h2>
        <p v-if="numArticle === '12.26'">Узнайте шансы своего дела. Выборка основана на 2036 постановлениях и решениях судов всех инстанций за 2018 – 2021 гг.</p>
        <p v-if="numArticle === '12.27'">Узнайте шансы своего дела. Выборка основана на 2330 постановлениях и решениях судов всех инстанций 2016-2020 гг.</p>
        <p v-if="numArticle === '12.8'">Узнайте шансы своего дела на возврат прав за управление в состоянии алкогольного опьянения. Автоматизированная выборка основана на 4227 постановлениях и решениях судов всех инстанций за 2019 – 2021 гг.</p>
        <p v-if="numArticle === '12.15.4(5)'">Узнайте шансы своего дела. Выборка основана на 1466 постановлениях и решениях судов всех инстанций.</p>
        <p v-if="numArticle === '12.2'">Узнайте шансы своего дела. Выборка основана на 2330 постановлениях и решениях судов всех инстанций 2016-2020 гг.</p>
        <router-link tag="div" :class="{active: numArticle===articles[0]}" :to="{name: 'services', params: {id: articles[0]}}" class="articles-container">
          <div class="title">12.26</div>
          <div class="subtitle">Отказ от медицинского освидетельствования</div>
        </router-link>
        <router-link tag="div" :class="{active: numArticle===articles[1]}" :to="{name: 'services', params: {id: articles[1]}}" class="articles-container">
          <div class="title">12.8</div>
          <div class="subtitle">Управление в состоянии опьянения</div>
        </router-link>
        <router-link tag="div" :class="{active: numArticle===articles[2]}" :to="{name: 'services', params: {id: articles[2]}}" class="articles-container">
          <div class="title">12.15.4(5)</div>
          <div class="subtitle">Выезд на встречную полосу</div>
        </router-link>
        <router-link tag="div" :class="{active: numArticle===articles[3]}" :to="{name: 'services', params: {id: articles[3]}}" class="articles-container">
          <div class="title">12.27</div>
          <div class="subtitle">Скрытие с места ДТП</div>
        </router-link>
        <router-link tag="div" :class="{active: numArticle===articles[4]}" :to="{name: 'services', params: {id: articles[4]}}" class="articles-container">
          <div class="title">12.2</div>
          <div class="subtitle">Управление без номеров и скрытие номеров</div>
        </router-link>
      </div>
      <div class="v-services_quest" v-if="numArticle === '12.2'">
        <div class="v-services_quest-container" v-for="quest in questions122" :key="quest.id">
          <span>{{quest.value}}</span>
          <div class="answer-wrapper">
            <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
            <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
          </div>
        </div>
      </div>
      <div class="v-services_quest" v-if="numArticle === '12.27'">
        <div class="v-services_quest-container" v-for="quest in questions1227" :key="quest.id">
          <span>{{quest.value}}</span>
          <div class="answer-wrapper">
            <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
            <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
          </div>
        </div>
      </div>
      <div class="v-services_quest" v-if="numArticle === '12.8'">
        <div class="v-services_quest-container" v-for="quest in questions128" :key="quest.id">
          <span>{{quest.value}}</span>
          <div class="answer-wrapper">
            <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
            <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
          </div>
        </div>
      </div>
      <div class="v-services_quest" v-if="numArticle === '12.26'">
        <div class="v-services_quest-container" v-for="quest in questions1226" :key="quest.id">
          <span>{{quest.value}}</span>
          <div class="answer-wrapper">
            <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
            <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
          </div>
        </div>
      </div>
      <div class="v-services_quest" v-if="numArticle === '12.15.4(5)'">
        <div class="v-services_quest-container" v-for="quest in questions1215" :key="quest.id">
          <span>{{quest.value}}</span>
          <div class="answer-wrapper">
            <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
            <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
          </div>
        </div>
      </div>
      <div ref="form" class="v-services_form">
        <div class="title">
          ВВЕДИТЕ ДАННЫЕ
          <p>Получите результаты проверки информации по базе судебных актов</p>
        </div>
        <form>
          <div class="form-wrapper">
            <label>
              <span>Ваше имя</span>
              <input type="text" v-model="name" placeholder="Введите имя">
            </label>
            <label>
              <span>E-mail</span>
              <input type="email" v-model="email" placeholder="E-mail">
            </label>
            <label>
              <span>Телефон</span>
              <input type="text" class="new-input" required placeholder="Ваш телефон" data-inputmask="'mask': '+9(999)999-99-99'"  v-model="phone">
            </label>
          </div>
        </form>
        <button type="submit" @click="outMessUrist" onclick="ym(72714331,'reachGoal','order')">Узнать результаты</button>
        <p>Отправляя форму вы даёте согласие на обработку персональных данных</p>
      </div>
      <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === '12.2'">
        <p>Добрый день!</p>
        <p>
          Направляем Вам предварительные результаты, основанные на автоматическом анализе 2330
          постановлений и решений по аналогичным делам. Искусственный интеллект
          определяет шансы на возврат прав
        </p>
        <p>
          Наши юристы готовы помочь Вам в том числе лично и по
          бесплатному телефону 8(800)201-32-36 предоставив бесплатную консультацию.
        </p>
        <p>А теперь по Вашему делу:</p>
        <div class="answer-wrapper">
          <div class="answer-container">
            <div v-if="questions122[0].answer === questions122[0].valueAnswerTrue" class="title">Суд был.</div>
            <div v-else class="title">Еще не было суда.</div>
            <div v-if="questions122[0].answer === questions122[0].valueAnswerTrue" class="subtitle">
              Если судом принято постановление о лишении прав, мы его обжалуем в суде вышестоящей инстанции и вернем права. Сотрудники ГИБДД  при составлении материалов дела, а  также судьи при рассмотрении дел допускают множество ошибок, которыми мы воспользуемся для того, чтобы вернуть права.
            </div>
            <div v-else class="subtitle">
              У нас будет много времени на проверку материалов дела на соответствие законодательству и на подготовку правовой позиции.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions122[1].answer === questions122[1].valueAnswerTrue" class="title">Управление автомобилем.</div>
            <div v-else class="title">Если автомобиль стоял.</div>
            <div v-if="questions122[1].answer === questions122[1].valueAnswerTrue" class="subtitle">
              Управление автомобилем без номеров или со скрытыми номерами является нарушением, но можно обойти лишение прав и штраф, оспорив протокол об АП и другие доказательства, имеющиеся в материалах дела
            </div>
            <div v-else class="subtitle">
              Если автомобиль стоял без номеров или со скрытыми номерами и сотрудники зафиксировали это как нарушение ст. 12.2, то это незаконно. Чтобы оспорить протокол, в данном случае  необходимо грамотно обосновать свою позицию в суде и исключить доказательства, представленные сотрудниками ГИБДД
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions122[2].answer === questions122[2].valueAnswerTrue" class="title">Если номера были скрыты.</div>
            <div v-else class="title">Если номера были скрыты не специально.</div>
            <div v-if="questions122[2].answer === questions122[2].valueAnswerTrue" class="subtitle">
              Если номера были скрыты, то нужно оспаривать протокол об АП и исключать фотографию/видео из материалов дела, а также можно прекратить дело из-за отсутствия доказательств того, что госномер был оборудован специальными материалами.
            </div>
            <div v-else class="subtitle">
              В этом случае нужно доказать в суде, что нарушения не было. А если номера были загрязнены снегом, льдом или грязью – то доказать, что номера стали нечитаемыми из-за погодных условий и получить штраф 500 рублей
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions122[3].answer === questions122[3].valueAnswerTrue" class="title">Доказательства, кроме протокола.</div>
            <div v-else class="title">Отсутствие доказательств.</div>
            <div v-if="questions122[3].answer === questions122[3].valueAnswerTrue" class="subtitle">
              Если есть показания свидетелей, фотографии или видеозапись в материалах дела, то их нужно проверить на соответствие требованиям закона, а при обнаружении нарушений – исключить из доказательственной базы
            </div>
            <div v-else class="subtitle">
              Отсутствие доказательств кроме протокола об административном правонарушении поможет выиграть дело, т.к. протокол об АП не может быть единственным доказательством в деле. Остается грамотно подготовить позицию для суда
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions122[4].answer === questions122[4].valueAnswerTrue" class="title">Копия протокола.</div>
            <div v-else class="title">Копия протокола не выдана.</div>
            <div v-if="questions122[4].answer === questions122[4].valueAnswerTrue" class="subtitle">
              Вам на руки должны были выдать копию протокола об АП. Это поможет в подготовке правовой позиции.
            </div>
            <div v-else class="subtitle">
              Копия протокола обязательно должна быть выдана вам, а если было нарушено это требование юрист докажет, что ваши права нарушены и суд вынесет постановление в вашу пользу
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions122[5].answer === questions122[5].valueAnswerTrue" class="title">Что Вы указали в протоколе? Согласились с нарушением?</div>
            <div v-else class="title">Что Вы указали в протоколе.</div>
            <div v-if="questions122[5].answer === questions122[5].valueAnswerTrue" class="subtitle">
              Ваше согласие в момент составления протокола может быть в суде представлено как «не разобрался в ситуации под давлением сотрудников и введения в заблуждение относительно существа вменяемого правонарушения». Поэтому от этих слов можно безболезненно отказаться.
            </div>
            <div v-else class="subtitle">
              Согласие или не согласие с протоколом не играет особой роли. То, что Вы не согласились на месте, безусловно, увеличивает наши шансы, показывая, что Вы изначально считали действия сотрудников ДПС неправомерными.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions122[6].answer === questions122[6].valueAnswerTrue" class="title">
              Видеозапись.
            </div>
            <div v-else class="title">Видеозаписи нет.</div>
            <div v-if="questions122[6].answer === questions122[6].valueAnswerTrue" class="subtitle">
              Проверьте в протоколе, указано ли то, что к нему прилагается фото/видео. Если такого указания нет или не соблюдены все требования, предъявляемые процессуальным законодательством к фотографии/видеозаписи, то ее можно исключить из доказательственной базы и выиграть дело, с чем мы успешно справляемся.
            </div>
            <div v-else class="subtitle">
              Отлично что ее нет. На одно доказательство придется исключать меньше.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions122[7].answer === questions122[7].valueAnswerTrue" class="title">Если есть свидетели.</div>
            <div v-else class="title">Если нет свидетелей.</div>
            <div v-if="questions122[7].answer === questions122[7].valueAnswerTrue" class="subtitle">
              Если есть свидетели мы проверим соответствие их показаний рапорту инспектора ГИБДД, протоколу об АП и иным доказательствам. Если обнаружим, что показания расходятся с другими доказательствами, то сможем оспорить их участие в качестве свидетелей.
            </div>
            <div v-else class="subtitle">
              Если нет свидетелей, то это пойдет нам на пользу. Наличие свидетелей необязательно и если есть какие-либо другие доказательства вашей вины, то необходимо будет оспорить их.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions122[8].answer === questions122[8].valueAnswerTrue" class="title">Ваши пассажиры.</div>
            <div v-else class="title">Отсутствие свидетелей.</div>
            <div v-if="questions122[8].answer === questions122[8].valueAnswerTrue" class="subtitle">
              Ваши пассажиры являются такими же свидетелями по делу и могут давать свои показания и
              доказать вашу невиновность. И необязательно, чтобы они были
              вписаны в протокол об административном правонарушении.
            </div>
            <div v-else class="subtitle">
              С Вашей стороны в данном случае не играют особой роли.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions122[9].answer === questions122[9].valueAnswerTrue" class="subtitle">
              При решении вопроса о назначении административного наказания суд учитывает характер
              совершенного правонарушения и иные обстоятельства. Конечно же, получить штраф не всем удается, но наши
              юристы добиваются этого, учитывая ситуацию клиента и судебную практику в его регионе.
            </div>
            <div v-else class="subtitle">
              Чтобы не получить штраф и остаться с правами необходимо грамотно подготовить линию
              защиты для суда, с указанием всех нарушений материального и процессуального законодательства.
            </div>
          </div>
        </div>
        <h5>Резюме</h5>
        <p>
          Нами разработано несколько способов для возврата прав за управление без номеров или со скрытыми номерами, в том числе и с получением штрафа. Как видно из предварительного автоматизированного анализа, у Вас есть все шансы на успех, но придется постараться этого успеха добиться. Есть положительные и отрицательные моменты, но после анализа протокола об административном правонарушении можно будет дать более точные шансы и определиться с окончательной линией защиты по Вашему делу.
        </p>
        <h3>Звоните по бесплатному номеру 8(800)201-32-36 и юрист предоставит Вам:</h3>
        <span>- бесплатные ходатайства</span><br>
        <span>- постановления и решения судов на которых основана эта информация</span><br>
        <span>- бесплатную консультацию и анализ материалов дела</span>
        <h3>
          С уважением,
          <br>
          Ваш Автоюрист
        </h3>
      </div>
      <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === '12.27'">
        <p>Добрый день!</p>
        <p>
          Направляем Вам предварительные результаты, основанные на автоматическом
          анализе 2330 постановлений и решений по аналогичным делам. Искусственный
          интеллект определяет шансы на возврат прав
        </p>
        <p>
          Наши юристы готовы помочь Вам в том числе лично и по бесплатному
          телефону 8(800)201-32-36 предоставив бесплатную консультацию.
        </p>
        <p>А теперь по Вашему делу:</p>
        <div class="answer-wrapper">
          <div class="answer-container">
            <div v-if="questions1227[0].answer === questions1227[0].valueAnswerTrue" class="title">Суд был.</div>
            <div v-else class="title">Еще не было суда.</div>
            <div v-if="questions1227[0].answer === questions1227[0].valueAnswerTrue" class="subtitle">
              Если судом принято постановление о лишении прав, мы его
              обжалуем в суде вышестоящей инстанции и вернем права.
            </div>
            <div v-else class="subtitle">
              У нас будет много времени на проверку материалов дела на соответствие
              законодательству и на подготовку правовой позиции.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1227[1].answer === questions1227[1].valueAnswerTrue" class="title">Было ДТП.</div>
            <div v-else class="title">Не было ДТП.</div>
            <div v-if="questions1227[1].answer === questions1227[1].valueAnswerTrue" class="subtitle">
              Если было ДТП нужно оспорить доказательства и исключить их из материалов дела для выигрыша дела.
            </div>
            <div v-else class="subtitle">
              В этом случае для выигрыша дела необходимо доказать, что контакта
              между автомобилями или иным участником ДТП не было, с чем успешно справляются юристы нашей компании.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1227[2].answer === questions1227[2].valueAnswerTrue" class="title">Повреждения незначительные.</div>
            <div v-else class="title">Повреждения серьезные.</div>
            <div v-if="questions1227[2].answer === questions1227[2].valueAnswerTrue" class="subtitle">
              В этом случае необходимо только грамотно сформулировать правовую позицию и мы спокойно
              сможем обойти лишение прав и арест.
            </div>
            <div v-else class="subtitle">
              В этом случае мы проверим показания свидетелей, рапорт, протокол об АП и другие документы на
              факт соответствия изложенной информации действительности и наличие недостатков при их составлении.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1227[3].answer === questions1227[3].valueAnswerTrue" class="title">Если скрывались с места ДТП</div>
            <div v-else class="title">Если не скрывались с места ДТП</div>
            <div v-if="questions1227[3].answer === questions1227[3].valueAnswerTrue" class="subtitle">
              необходимо проверить наличие доказательств вашей вины, правильность составления процессуальных
              документов, а  также полноту проведенного административного расследования. При отсутствии доказательств
              или неправильном составлении документов можно выиграть дело, главное - грамотно подготовить линию защиты.
            </div>
            <div v-else class="subtitle">
              В этом случае необходимо правильно сформулировать позицию защиты,
              собрать и предоставить в суд свои доказательства того, что скрытия с места ДТП не было.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1227[4].answer === questions1227[4].valueAnswerTrue" class="title">Имеются претензии у второго участника ДТП.</div>
            <div v-else class="title">У второго участника ДТП нет претензий.</div>
            <div v-if="questions1227[4].answer === questions1227[4].valueAnswerTrue" class="subtitle">
              Юрист попытается договориться с потерпевшим, чтобы в суде отказался от своих претензий к вам.
            </div>
            <div v-else class="subtitle">
              Отсутствие претензий пойдет нам только на руку и поможет остаться с правами.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1227[5].answer === questions1227[5].valueAnswerTrue" class="title">Что Вы указали в протоколе? Согласились с нарушением?</div>
            <div v-else class="title">Что Вы указали в протоколе</div>
            <div v-if="questions1227[5].answer === questions1227[5].valueAnswerTrue" class="subtitle">
              Ваше согласие в момент составления протокола может быть в суде представлено как «не разобрался в
              ситуации под давлением сотрудников и введения в заблуждение относительно существа
              вменяемого правонарушения». Поэтому от этих слов можно безболезненно отказаться.
            </div>
            <div v-else class="subtitle">
              Согласие или не согласие с протоколом не играет особой роли. То, что Вы не согласились
              на месте, безусловно, увеличивает наши шансы, показывая, что Вы изначально считали
              действия сотрудников ДПС неправомерными.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1227[6].answer === questions1227[6].valueAnswerTrue" class="title">
              Проверьте в протоколе, указано ли то, что к нему прилагается видео.
            </div>
            <div v-else class="title">Видеозапись.</div>
            <div v-if="questions1227[6].answer === questions1227[6].valueAnswerTrue" class="subtitle">
              Если такого указания нет или не соблюдены все требования, предъявляемые процессуальным
              законодательством к видеозаписи, то ее можно исключить из доказательственной
              базы и выиграть дело, с чем мы успешно справляемся.
            </div>
            <div v-else class="subtitle">
              Отлично что ее нет. На одно доказательство придется исключать меньше. Вообще же, видеозапись
              нарушения не является обязательным доказательством, поскольку суды устанавливают вину
              водителя или отсутствие таковой уже на основании заявления второго участника ДТП,
              протокола, рапорта и схемы и иных материалов.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1227[7].answer === questions1227[7].valueAnswerTrue" class="title">Если есть свидетели.</div>
            <div v-else class="title">Если нет свидетелей.</div>
            <div v-if="questions1227[7].answer === questions1227[7].valueAnswerTrue" class="subtitle">
              мы проверим соответствие их показаний рапорту инспектора ГИБДД, протоколу об АП и иным доказательствам.
              Если обнаружим, что показания расходятся с другими доказательствами,
              то сможем оспорить их участие в качестве свидетелей.
            </div>
            <div v-else class="subtitle">
              Если нет свидетелей, то это пойдет нам на пользу. Наличие свидетелей необязательно и если
              есть какие-либо другие доказательства вашей вины, то необходимо будет оспорить их.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1227[8].answer === questions1227[8].valueAnswerTrue" class="title">Ваши пассажиры.</div>
            <div v-else class="title">Отсутствие свидетелей.</div>
            <div v-if="questions1227[8].answer === questions1227[8].valueAnswerTrue" class="subtitle">
              Ваши пассажиры являются такими же свидетелями по делу и могут давать свои показания и
              доказать вашу невиновность. И необязательно, чтобы они были
              вписаны в протокол об административном правонарушении.
            </div>
            <div v-else class="subtitle">
              С Вашей стороны в данном случае не играют особой роли.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1227[9].answer === questions1227[9].valueAnswerTrue" class="subtitle">
              При решении вопроса о назначении административного наказания суд учитывает характер
              совершенного правонарушения и иные обстоятельства. Конечно же, получить арест на практике сложно,
              но наши юристы добиваются этого, учитывая ситуацию клиента и судебную практику в его регионе.
            </div>
            <div v-else class="subtitle">
              Чтобы не получить арест и остаться с правами необходимо грамотно подготовить линию защиты для суда,
              с указанием всех нарушений материального и процессуального законодательства.
            </div>
          </div>
        </div>
        <h5>Резюме</h5>
        <p>
          Нами разработано несколько способов для возврата прав за оставление места ДТП,
          в том числе и с получением ареста. Как видно из предварительного автоматизированного анализа,
          у Вас есть все шансы на успех, но придется постараться этого успеха добиться.
          Есть положительные и отрицательные моменты, но после анализа протокола об административном
          правонарушении можно будет дать более точные шансы и определиться с окончательной линией защиты
          по Вашему делу.
        </p>
        <h3>Звоните по бесплатному номеру 8(800)201-32-36 и юрист предоставит Вам:</h3>
        <span>- бесплатные ходатайства</span><br>
        <span>- постановления и решения судов на которых основана эта информация</span><br>
        <span>- бесплатную консультацию и анализ материалов дела</span>
        <h3>
          С уважением,
          <br>
          Ваш Автоюрист
        </h3>
      </div>
      <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === '12.8'">
        <p>Добрый день!</p>
        <p>
          Направляем Вам предварительные результаты, основанные на автоматическом
          анализе 4227 постановлений и решений по аналогичным делам. Искусственный
          интеллект определяет шансы на возврат прав
        </p>
        <p>
          Наши юристы готовы помочь Вам в том числе лично и по бесплатному
          телефону 8(800)201-32-36 предоставив бесплатную консультацию.
        </p>
        <p>А теперь по Вашему делу:</p>
        <div class="answer-wrapper">
          <div class="answer-container">
            <div v-if="questions128[0].answer === questions128[0].valueAnswerTrue" class="title">Суд был.</div>
            <div v-else class="title">Еще не было суда.</div>
            <div v-if="questions128[0].answer === questions128[0].valueAnswerTrue" class="subtitle">
              У Вас уже состоялся суд и Вас лишили прав. Закон позволяет нам включиться в работу в
              любой момент и обжаловать постановление в судах вышестоящих инстанций.
            </div>
            <div v-else class="subtitle">
              Поскольку суд еще не назначен у нас есть дополнительное время на подготовку.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions128[1].answer === questions128[1].valueAnswerTrue" class="title">Был в состоянии опьянения.</div>
            <div v-else class="title">Не был пьян.</div>
            <div v-if="questions128[1].answer === questions128[1].valueAnswerTrue" class="subtitle">
              В этом случае необходимо проверить соответствие процедуры освидетельствования процессуальному
              законодательству: предложили пройти освидетельствование на месте, выдали копии протоколов,
              были понятые или видеозапись и т.д.
            </div>
            <div v-else class="subtitle">
              Это является огромным преимуществом для Вас, но нужно правильно составить линию защиты для суда.
              Наши юристы участвовали во многих спорах, где водителей привлекли к ответственности,
              хотя они не находились в состоянии опьянения и оспорили постановление.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions128[2].answer === questions128[2].valueAnswerTrue" class="title">Если прошли освидетельствование на состояние опьянения на месте.</div>
            <div v-else class="title">Возили в наркологию, на месте не предлагали.</div>
            <div v-if="questions128[2].answer === questions128[2].valueAnswerTrue" class="subtitle">
              Если прошли освидетельствование на состояние опьянения на месте, то обязательно должны быть
              понятые или видео, а также соблюдена процедура освидетельствования.
              Нарушение этих правил влечет отмену штрафа и лишения прав.
            </div>
            <div v-else class="subtitle">
              Это грубейшее нарушение КоАП РФ. Инспектор ГИБДД обязан предложить
              пройти освидетельствование на состояние опьянения на месте.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions128[3].answer === questions128[3].valueAnswerTrue" class="title">Копии протоколов.</div>
            <div v-else class="title">Копии протоколов.</div>
            <div v-if="questions128[3].answer === questions128[3].valueAnswerTrue" class="subtitle">
              Вами на руки должны были выдать протокол об отстранении, протокол о направлении на медицинское
              освидетельствование, акт освидетельствования на месте, акт медицинского освидетельствования,
              протокол об административном правонарушении. Проверьте, копии каких документов вам выдали,
              если каких-то нет, с ними можно ознакомиться в суде.
            </div>
            <div v-else class="subtitle">
              Копии протоколов обязательно должны быть выданы лицу, в отношении которого возбуждено
              административное дело. В случае нарушения этого правила протокол подлежит исключению из материалов дела.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions128[4].answer === questions128[4].valueAnswerTrue" class="title">Что Вы указали в протоколе?</div>
            <div v-else class="title">Что Вы указали в протоколе?</div>
            <div v-if="questions128[4].answer === questions128[4].valueAnswerTrue" class="subtitle">
              Согласие или не согласие с протоколом не играет особой роли. Ваше согласие в момент составления
              протокола может быть в суде представлено как «не разобрался в ситуации под давлением сотрудников
              и введения в заблуждение относительно существа вменяемого правонарушения».
              Поэтому от этих слов можно безболезненно отказаться в суде. Как правило, это массивный документ
              или несколько документов, которые приобщаются к материалам дела и служат таким
              же доказательством, как и протоколы сотрудников ДПС.
            </div>
            <div v-else class="subtitle">
              Что Вы указали в протоколе – согласие или не согласие с протоколом не играет особой роли.
              То, что Вы не согласились на месте, безусловно, увеличивает наши шансы, показывая,
              что Вы изначально считали действия сотрудников ДПС неправомерными.
              Теперь наша задача доказать это, опираясь на все обстоятельства и материалы дела.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions128[5].answer === questions128[5].valueAnswerTrue" class="title">
              Проверьте в протоколе (если он у Вас есть на руках), указано ли то, что к нему прилагается видео.
            </div>
            <div v-else class="title">Видеозапись.</div>
            <div v-if="questions128[5].answer === questions128[5].valueAnswerTrue" class="subtitle">
              Если такого указания нет или не указано каким техническим средством она была
              произведена – видеозапись подлежит исключению из доказательств.
              Также еще есть несколько требований к видеозаписи, при нарушении которых можно
              исключить видеозапись, что могут сделать наши юристы.
            </div>
            <div v-else class="subtitle">
              Отлично что она не велась – с понятыми работать всегда проще. На одно доказательство придется исключать меньше.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions128[6].answer === questions128[6].valueAnswerTrue" class="title">
              Участие понятыхю.
            </div>
            <div v-else class="title">
              Обязательно должны быть понятые либо вестись видеозапись.
            </div>
            <div v-if="questions128[6].answer === questions128[6].valueAnswerTrue" class="subtitle">
              Участие понятых при составлении административного материала регламентировано КоАП РФ.
              А в случае нарушения его норм можно исключить объяснения понятых из доказательств.
              Эта процедура сложная, но мы знаем, как сделать так, чтобы вы остались с правами,
              найдя ошибки в объяснениях понятых или наоборот используя их в нашу пользу.
            </div>
            <div v-else class="subtitle">
              Отсутствие понятых и видео является грубейшим нарушением процессуальных норм.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions128[7].answer === questions128[7].valueAnswerTrue" class="title">
              Свидетели.
            </div>
            <div v-else class="title">Если нет свидетелей.</div>
            <div v-if="questions128[7].answer === questions128[7].valueAnswerTrue" class="subtitle">
              Как правило, это, например, водители авто, которых остановили вместе с Вами, люди, которые сообщили
              о том ,что вы находитесь в состоянии опьянения или мимо проходящие граждане, а также может быть
              привлечен второй инспектор ДПС. Привлечение инспектора ДПС является нарушением КоАП, но главной
              сложностью является то, что суды не обращают на
              это внимание и водители не могут самостоятельно выиграть дело.
            </div>
            <div v-else class="subtitle">
              То, что в протокол не вписаны свидетели немного
              упрощает нашу работу, но не исключает проведение всех остальных действий.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions128[8].answer === questions128[8].valueAnswerTrue" class="title">Пассажиры в Вашем авто.</div>
            <div v-else class="title">
              Свидетели со стороны водителя.</div>
            <div v-if="questions128[8].answer === questions128[8].valueAnswerTrue" class="subtitle">
              Пассажиры в Вашем авто также могут быть свидетелями даже если их не вписали в протокол.
              Они могут выступить в суде по выстроенной юристом линии защиты. Обсудите с юристом сложность
              Вашего дела для того чтобы привлечь их для выступления в суде. Юрист подготовит для них речь.
            </div>
            <div v-else class="subtitle">
              Свидетели со стороны водителя могут и не быть. Наличие свидетелей необязательно,
              главное- найти другие доказательства, которые помогут выиграть дело.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions128[9].answer === questions128[9].valueAnswerTrue" class="subtitle">
              А теперь о том, как назначается минимальное или максимальное наказание. Пока мы не вступили в процесс
              в суде мы не знаем, что инспектор указал в рапорте (поскольку рапорт прилагается к материалам дела),
              не видели другие материалы дела. Помните, что любое даже незначительное обстоятельство для суда является
              достаточным основанием (ведь суд действует в соответствии с законом и собственными убеждениями)
              для принятия в виде наказания максимального порога по статье. Если ранее Вас привлекали за управление
              в состоянии опьянения (карточка с нарушениями приобщается к материалам дела) это отягчающее
              обстоятельство и означает максимальную санкцию.
              Были ли у Вас какие-нибудь штрафы в течение года? Если да, то это также отягчающее обстоятельство.
              В этом случае, суд может применить максимальное наказание.
            </div>
            <div v-else class="subtitle">
              Для назначения административного наказания необходимо наличие доказательств, которые соответствуют
              требованиям законодательства. Например, если протокол об АП не соответствует КоАП РФ,
              его можно исключить из материалов дела и остаться с правами.
            </div>
          </div>
        </div>
        <h5>Резюме</h5>
        <p>
          Есть 4 основных способа возврата прав за управление в состоянии опьянения.
          Как видно из предварительного автоматизированного анализа, у Вас есть все шансы на успех,
          но придется постараться этого успеха добиться. Есть положительные и отрицательные моменты,
          но после анализа протокола об административном правонарушении можно будет дать более точные
          шансы и определиться с окончательной линией защиты по Вашему делу и выбрать
          как раз один из 4-х основных вариантов.
        </p>
        <h3>Звоните по бесплатному номеру 8(800)201-32-36 и юрист предоставит Вам:</h3>
        <span>- бесплатные ходатайства</span><br>
        <span>- постановления и решения судов на которых основана эта информация</span><br>
        <span>- бесплатную консультацию и анализ материалов дела</span>
        <h3>
          С уважением,
          <br>
          Ваш Автоюрист
        </h3>
      </div>
      <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === '12.26'">
        <p>Добрый день!</p>
        <p>
          Направляем Вам предварительные результаты, основанные на автоматическом
          анализе 2036 постановлений и решений по аналогичным делам. Искусственный
          интеллект определяет шансы на возврат прав
        </p>
        <p>
          Наши юристы готовы помочь Вам в том числе лично и по бесплатному
          телефону 8(800)201-32-36 предоставив бесплатную консультацию.
        </p>
        <p>А теперь по Вашему делу:</p>
        <div class="answer-wrapper">
          <div class="answer-container">
            <div v-if="questions1226[0].answer === questions1226[0].valueAnswerTrue" class="title">Суд был.</div>
            <div v-else class="title">Еще не было суда.</div>
            <div v-if="questions1226[0].answer === questions1226[0].valueAnswerTrue" class="subtitle">
              У Вас уже состоялся суд и Вас лишили прав. Закон позволяет нам включиться в работу в любой момент,
              даже если Вы уже сдали водительское удостоверение и вернуть его досрочно
            </div>
            <div v-else class="subtitle">
              Поскольку суд еще не назначен у нас есть дополнительное время на подготовку
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1226[1].answer === questions1226[1].valueAnswerTrue" class="title">Отказ от медосвидетельствования.</div>
            <div v-else class="title">На месте не предложили, сразу в наркологию.</div>
            <div v-if="questions1226[1].answer === questions1226[1].valueAnswerTrue" class="subtitle">
              Этот факт должен быть зафиксирован на видео или понятыми, а также соблюдена вся процедура
              освидетельствования на состояние опьянения, при нарушении которой наши юристы успешно оспаривают лишение прав.
            </div>
            <div v-else class="subtitle">
              Это грубое нарушение. Здесь не возникает сложностей для освобождения от ответственности.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1226[2].answer === questions1226[2].valueAnswerTrue" class="title">Копии протоколов.</div>
            <div v-else class="title">Копии протоколов.</div>
            <div v-if="questions1226[2].answer === questions1226[2].valueAnswerTrue" class="subtitle">
              Вам на руки должны были выдать копии всех протоколов и актов. Это поможет в подготовке правовой позиции.
            </div>
            <div v-else class="subtitle">
              Копии протоколов обязательно должны быть выданы вам, а если было нарушено это требование юрист докажет,
              что ваши права нарушены и суд вынесет постановление в вашу пользу
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1226[3].answer === questions1226[3].valueAnswerTrue" class="title">Что Вы указали в протоколе.</div>
            <div v-else class="title">Что Вы указали в протоколе.</div>
            <div v-if="questions1226[3].answer === questions1226[3].valueAnswerTrue" class="subtitle">
              Согласие или не согласие с протоколом не играет особой роли. Ваше согласие в момент составления протокола
              может быть в суде представлено как «не разобрался в ситуации под давлением сотрудников и введения в
              заблуждение относительно существа вменяемого правонарушения». Поэтому от этих слов можно безболезненно
              отказаться. Мы сделаем это при оспаривании постановления. Как правило, это массивный документ или несколько
              документов, которые приобщаются к материалам дела и служат таким же доказательством, как и протоколы сотрудников ДПС.
            </div>
            <div v-else class="subtitle">
              Что Вы указали в протоколе – согласие или не согласие с протоколом не играет особой роли.
              То, что Вы не согласились на месте, безусловно, увеличивает наши шансы, показывая,
              что Вы изначально считали действия сотрудников ДПС неправомерными.
              Теперь наша задача доказать это, опираясь на нарушения в материалах дела.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1226[4].answer === questions1226[4].valueAnswerTrue" class="title">
              Проверьте в протоколе (если он у Вас есть на руках), указано ли то, что к нему прилагается видео.
            </div>
            <div v-else class="title">Видеозапись.</div>
            <div v-if="questions1226[4].answer === questions1226[4].valueAnswerTrue" class="subtitle">
              Если такого указания нет или не указано каким техническим средством она была произведена – видеозапись
              подлежит исключению из доказательств. Также видеозапись можно исключить, если она прерывается,
              объединена из нескольких частей или снята на личный телефон инспектора.
            </div>
            <div v-else class="subtitle">
              Отлично что она не велась – с понятыми работать всегда проще. На одно доказательство придется исключать меньше.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1226[5].answer === questions1226[5].valueAnswerTrue" class="title">
              Участие понятых.
            </div>
            <div v-else class="title">Обязательно должны быть понятые либо вестись видеозапись.</div>
            <div v-if="questions1226[5].answer === questions1226[5].valueAnswerTrue" class="subtitle">

              Участие понятых при составлении административного материала регламентировано КоАП РФ.
              А в случае нарушения его норм (например, понятые были формально, указали не те адреса)
              можно исключить объяснения понятых из доказательств. Эта процедура сложная, но мы знаем,
              как сделать так, чтобы остаться с правами, найдя ошибки в объяснениях понятых.
            </div>
            <div v-else class="subtitle">
              Обязательно должны быть понятые либо вестись видеозапись при проведении процессуальных действий,
              предусмотренных гл. 27 и ст. 28.1.1 КоАП РФ. Отсутствие понятых и видео является грубейшим нарушением процессуальных норм.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1226[6].answer === questions1226[6].valueAnswerTrue" class="title">
              Свидетели.
            </div>
            <div v-else class="title">
              Свидетели.
            </div>
            <div v-if="questions1226[6].answer === questions1226[6].valueAnswerTrue" class="subtitle">
              Как правило, это другие водители или мимо проходящие люди. В случае, если телефон свидетелей указан,
              юрист свяжется с ними и уточнит все обстоятельства чтобы обернуть их в Вашу пользу. Если телефонов нет
              и указан только адрес, будем вызывать их в суд. Свидетели являются в суд в 0,2% случаев.
              А в 20% случаев свидетели указывают недостоверные адреса. В 9% случаев, свидетелем указывается
              второй сотрудник ДПС из экипажа – все эти обстоятельства позволят нам трактовать неустранимые сомнения в Вашу пользу.
            </div>
            <div v-else class="subtitle">
              То, что в протокол не вписаны свидетели немного упрощает нашу работу, но не исключает проведение всех остальных действий.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1226[7].answer === questions1226[7].valueAnswerTrue" class="title">
              Пассажиры в Вашем авто .
            </div>
            <div v-else class="title">
              Наличие свидетелей.
            </div>
            <div v-if="questions1226[7].answer === questions1226[7].valueAnswerTrue" class="subtitle">
              Пассажиры в Вашем авто также могут быть свидетелями даже если их не вписали в протокол.
              Они могут выступить в суде по выстроенной юристом линии защиты. Обсудите с юристом сложность
              Вашего дела для того чтобы привлечь их для выступления в суде. Юрист подготовит для них речь.
            </div>
            <div v-else class="subtitle">
              Наличие свидетелей с вашей стороны необязательно и это не играет особой роли.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1226[8].answer === questions1226[8].valueAnswerTrue" class="subtitle">
              А теперь о том, как назначается минимальное или максимальное наказание. Пока мы не вступили в
              процесс в суде мы не знаем, что инспектор указал в рапорте (поскольку рапорт прилагается к материалам дела),
              не видели другие материалы дела. Помните, что любое даже незначительное обстоятельство для суда является
              достаточным основанием (ведь суд действует в соответствии с законом и собственными убеждениями)
              для принятия в виде наказания максимального порога по статье. Если ранее Вас привлекали за управление
              в состоянии опьянения (карточка с нарушениями приобщается к материалам дела) это отягчающее обстоятельство
              и означает максимальную санкцию.
              Были ли у Вас какие-нибудь штрафы в течение года? Если да, то это также отягчающее обстоятельство.
              В этом случае, суд может применить максимальное наказание.
            </div>
            <div v-else class="subtitle">
              Вообще, чтобы уйти от административной ответственности нужно проверить могут ли доказательства быть
              использованы судом таковыми, ведь, сотрудниками ГИБДД допускается
              огромное количество ошибок при составлении административного материала.
            </div>
          </div>
        </div>
        <h5>Резюме</h5>
        <p>
          Нами используется как минимум 3 основных способа возврата прав за отказ
          от прохождения медицинского освидетельствования. Как видно из предварительного автоматизированного анализа,
          у Вас есть шансы на успех, но придется много поработать чтобы этого успеха добиться. Есть положительные и
          отрицательные моменты, есть сложные, а есть простые, но после анализа протокола об административном
          правонарушении можно будет дать более точные шансы в % и определиться с окончательной линией защиты по
          Вашему делу и выбрать как раз один из 3-х основных вариантов.
        </p>
        <h3>Звоните по бесплатному номеру 8(800)201-32-36 и юрист предоставит Вам:</h3>
        <span>- бесплатные ходатайства</span><br>
        <span>- постановления и решения судов на которых основана эта информация</span><br>
        <span>- бесплатную консультацию и анализ материалов дела</span>
        <h3>
          С уважением,
          <br>
          Ваш Автоюрист
        </h3>
      </div>
      <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === '12.15.4(5)'">
        <p>Добрый день!</p>
        <p>
          Направляем Вам предварительные результаты, основанные на автоматическом
          анализе 1466 постановлений и решений по аналогичным делам. Искусственный
          интеллект определяет шансы на возврат прав
        </p>
        <p>
          Наши юристы готовы помочь Вам в том числе лично и по бесплатному
          телефону 8(800)201-32-36 предоставив бесплатную консультацию.
        </p>
        <p>А теперь по Вашему делу:</p>
        <div class="answer-wrapper">
          <div class="answer-container">
            <div v-if="questions1215[0].answer === questions1215[0].valueAnswerTrue" class="title">Суд был.</div>
            <div v-else class="title">Еще не было суда.</div>
            <div v-if="questions1215[0].answer === questions1215[0].valueAnswerTrue" class="subtitle">
              У Вас уже состоялся суд и Вас лишили прав. Закон позволяет нам включиться в работу в
              любой момент и обжаловать постановление в судах вышестоящих инстанций.
            </div>
            <div v-else class="subtitle">
              Поскольку суд еще не назначен у нас есть дополнительное время на подготовку.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1215[1].answer === questions1215[1].valueAnswerTrue" class="title">У Вас первый выезд на встречку или повторный?</div>
            <div v-else class="title">У Вас первый выезд на встречку или повторный?</div>
            <div v-if="questions1215[1].answer === questions1215[1].valueAnswerTrue" class="subtitle">
              Совершение нарушения первый раз не означает, что Вас признают невиновным автоматически или также
              автоматически назначат штраф. За свои права придется бороться. Но давайте разберемся подробнее
              исходя из Ваших ответов на все вопросы.
            </div>
            <div v-else class="subtitle">
              Если это повторный выезд в течение года Вас может ждать лишение прав на 1 год. Поэтому необходимо
              основательно подготовиться к защите. Если же это было более года назад, то Вы попросту признаетесь
              злостным нарушителем и к Вам, если ничего не предпринимать, будет применено наказание в виде лишения прав.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1215[2].answer === questions1215[2].valueAnswerTrue" class="title">Штрафы за нарушение ПДД.</div>
            <div v-else class="title">Штрафы за нарушение ПДД.</div>
            <div v-if="questions1215[2].answer === questions1215[2].valueAnswerTrue" class="subtitle">
              В Вашем случае имеется отягчающее обстоятельство – нарушение ПДД, что является основанием для назначения
              наказания именно в виде лишения права управления и не важно оплачены они или нет. Но наши юристы успешно
              с этим справляются и доказывают в суде, что разные правонарушения не могут ложиться в основу более сурового
              наказания. А также исключают документы из материалов дела, указав на процессуальные нарушения,
              допущенные при их составлении, что поможет оспорить лишение прав.
            </div>
            <div v-else class="subtitle">
              В любом случае, отсутствие нарушений, в том числе с камер автоматической видеофиксации, увеличивает шансы
              на то, чтобы в при худшем исходе Вам в качестве наказания назначили штраф. Но помните, судья при вынесении
              постановления руководствуется в том числе внутренними убеждениями и мы к этому еще вернемся, а пока пойдем по фактам.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1215[3].answer === questions1215[3].valueAnswerTrue" class="title">
              Составлялась ли при Вас схема?
            </div>
            <div v-else class="title">Схема.</div>
            <div v-if="questions1215[3].answer === questions1215[3].valueAnswerTrue" class="subtitle">
              При этом, внимательно посмотрите свою копию протокола. Указано ли там, что к протоколу прилагается схема.

              То, что схема была составлена ИДПС, кстати, не лишает нас права подготовить свою схему, что мы обязательно
              сделаем. Таким образом, правильно составив и юридически грамотно оформив схему мы создаем противоречие
              между представленными в суд доказательствами добиваясь того чтобы все неустранимые сомнения трактовались
              в пользу лица, привлекаемого к административной ответственности.
            </div>
            <div v-else class="subtitle">
              То, что схема не была составлена ИДПС при вас дает нам возможность подготовить свою схему и приобщить ее в качестве доказательства в суде. Однако, посмотрите внимательно протокол раздел «прилагается». Возможно, там упоминается то обстоятельство, что схема прилагается. Это дополнительное основание для ее исключения из доказательств.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1215[4].answer === questions1215[4].valueAnswerTrue" class="title">Что Вы указали в протоколе.</div>
            <div v-else class="title">Что Вы указали в протоколе.</div>
            <div v-if="questions1215[4].answer === questions1215[4].valueAnswerTrue" class="subtitle">
              Согласие или не согласие с протоколом не играет особой роли. Ваше согласие в момент составления протокола может быть в суде представлено как «не разобрался в ситуации под давлением сотрудников и введения в заблуждение относительно существа вменяемого правонарушения». Поэтому от этих слов можно безболезненно отказаться.
            </div>
            <div v-else class="subtitle">
              Согласие или не согласие с протоколом не играет особой роли. То, что Вы не согласились на месте, безусловно, увеличивает наши шансы, показывая, что Вы изначально считали действия сотрудников ДПС неправомерными.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1215[5].answer === questions1215[5].valueAnswerTrue" class="title">
              Проверьте в протоколе, указано ли то, что к нему прилагается видео или фото.
            </div>
            <div v-else class="title">Видеозапись/фотография.</div>
            <div v-if="questions1215[5].answer === questions1215[5].valueAnswerTrue" class="subtitle">
              Если такого указания нет или не указано каким техническим средством фотография или видеозапись была снята – видеозапись или фотография подлежит исключению из доказательств. И даже если в протоколе указано, что прилагается фото или видео, то мы можем его оспорить, так как законодательством предусмотрены требования к видео/фото-доказательствам, которые нарушаются сотрудниками ГИБДД
            </div>
            <div v-else class="subtitle">
              Отлично что ее нет. На одно доказательство придется исключать меньше. Вообще же, видеозапись и фотография нарушения не является обязательным доказательством, поскольку суды устанавливают вину водителя или отсутствие таковой уже на основании протокола, рапорта и схемы.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1215[6].answer === questions1215[6].valueAnswerTrue" class="title">
              Свидетели.
            </div>
            <div v-else class="title">
              Свидетели.
            </div>
            <div v-if="questions1215[6].answer === questions1215[6].valueAnswerTrue" class="subtitle">
              Как правило, это, например, водители авто, которых Вы обогнали или которые находились в момент совершения нарушения рядом с ИДПС. В случае, если телефон свидетелей указан, мы свяжемся с ними и уточним все обстоятельства. Если телефонов нет и указан только адрес, будем вызывать их в суд. Свидетели являются в суд в 0,2% случаев. А в 20% случаев свидетели указывают недостоверные адреса. В 9% случаев, свидетелем указывается второй сотрудник ДПС из экипажа – все эти обстоятельства позволят нам трактовать неустранимые сомнения в Вашу пользу.
            </div>
            <div v-else class="subtitle">
              То, что в протокол не вписаны свидетели немного упрощает нашу работу, но не исключает проведение всех остальных действий.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1215[7].answer === questions1215[7].valueAnswerTrue" class="title">
              Пассажиры в Вашем авто.
            </div>
            <div v-else class="title">Пассажиры в Вашем авто.</div>
            <div v-if="questions1215[7].answer === questions1215[7].valueAnswerTrue" class="subtitle">
              Пассажиры в Вашем авто также могут быть свидетелями даже если их не вписали в протокол. Они могут подписать составленную нами схему и выступить в суде. Обсудите с юристом сложность Вашего дела для того чтобы привлечь их для выступления в суде. Юрист подготовит для них речь.
            </div>
            <div v-else class="subtitle">
              Отсутствие свидетелей с Вашей стороны в данном случае не играют особой роли.
            </div>
          </div>
          <div class="answer-container">
            <div v-if="questions1215[8].answer === questions1215[8].valueAnswerTrue" class="subtitle">
              А теперь о том, как назначается минимальное или максимальное наказание. Пока мы не вступили в процесс в суде мы не знаем, что инспектор указал в рапорте (поскольку рапорт прилагается к материалам дела). Одним из оснований назначить максимальный срок лишения является указание в рапорте на то, что «при совершении нарушения водитель своими действия создал общественную опасность, угрожающую жизни и здоровью других участников движения». Конечно же, фраза носит формальный характер, но для суда является достаточным основанием (ведь суд действует в соответствии с законом и собственными убеждениями) для принятия в виде наказания максимального порога по статье.
              Основанием для назначения наказания в виде лишения права управления в 72% случаев служат отягчающие обстоятельства среди которых, в том числе, любые штрафы ПДД, даже зафиксированные камерой (например, за стоп-линию или незначительное превышение скорости). Юристы, разумеется, учитывают это и указывают как недопустимое в жалобах, объяснениях и других документах. Если подобное не указывается и не оспаривается, судья принимает решение по своему усмотрению.
            </div>
            <div v-else class="subtitle">
              Чтобы оспорить ответственность за выезд на встречную полосу движения необходимо посмотреть соблюден ли процессуальный порядок составления административного материала. Любое нарушение в материалах дела может стать причиной, по которой уйдете от ответственности.
            </div>
          </div>
        </div>
        <h5>Резюме</h5>
        <p>
          Как видно из предварительного автоматизированного анализа, у Вас есть все шансы на успех, но придется постараться этого успеха добиться. Есть положительные и отрицательные моменты, но после анализа протокола об административном правонарушении можно будет дать более точные шансы и определиться с окончательной линией защиты по Вашему делу.
        </p>
        <h3>Звоните по бесплатному номеру 8(800)201-32-36 и юрист предоставит Вам:</h3>
        <span>- бесплатные ходатайства</span><br>
        <span>- постановления и решения судов на которых основана эта информация</span><br>
        <span>- бесплатную консультацию и анализ материалов дела</span>
        <h3>
          С уважением,
          <br>
          Ваш Автоюрист
        </h3>
      </div>
    </div>
    <notice
      v-if="isPopup"
      @removePopup="removePopup"
      :isPopup="isPopup"
      :title="title"
      :subtitle="subtitle"
    />
  </div>
</template>

<script>
import axios from 'axios'
import Inputmask from 'inputmask'
import notice from './notification'
export default {
  name: 'v-services',
  components: { notice },
  data () {
    return {
      articles: [
        '12.26', '12.8', '12.15.4(5)', '12.27', '12.2'
      ],
      questions122: [
        { value: 'Был ли суд?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'Управляли ли автомобилем в момент фиксации нарушения?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'Были ли номера скрыты специально?', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' },
        { value: 'Имеются ли доказательства, кроме протокола об АП?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' },
        { value: 'Выдали ли Вам копию протокола?', answer: 5, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '5' },
        { value: 'Что вы указали в протоколе и в письменных объяснениях?', answer: 6, valueAnswerTrue: 'Согласился', valueAnswerFalse: 'Не согласился', id: '6' },
        { value: 'Имеется ли в материалах дела фото/видео нарушения?', answer: 7, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '7' },
        { value: 'Привлекались ли свидетели?', answer: 8, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '8' },
        { value: 'Были ли пассажиры у Вас в авто?', answer: 9, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '9' },
        { value: 'Хочу узнать, как получить штраф', answer: 10, valueAnswerTrue: 'Узнать', valueAnswerFalse: 'Спасибо, не нужно', id: '10' }
      ],
      questions1215: [
        { value: 'Был ли суд?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'У Вас первый выезд на встречку или повторный?', answer: 2, valueAnswerTrue: 'Первый', valueAnswerFalse: 'Повторный', id: '2' },
        { value: 'Были ли у Вас штрафы за нарушение ПДД в течение года?', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' },
        { value: 'Составлялась ли при Вас схема?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' },
        { value: 'Что Вы указали в протоколе?', answer: 5, valueAnswerTrue: 'Согласился', valueAnswerFalse: 'Не согласился', id: '5' },
        { value: 'Имеется ли у ДПС фотография или видеозапись нарушения?', answer: 6, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '6' },
        { value: 'Привлекались ли свидетели?', answer: 7, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '7' },
        { value: 'Были ли пассажиры у Вас в авто?', answer: 8, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '8' },
        { value: 'Хочу узнать, в каких случаях назначат 4 или 6 месяцев лишения', answer: 9, valueAnswerTrue: 'Узнать', valueAnswerFalse: 'Спасибо, не нужно', id: '9' }
      ],
      questions1226: [
        { value: 'Был ли суд?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Еще нет', id: '1' },
        { value: 'Проходили ли Вы на месте освидетельствование?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Не предлагали', id: '2' },
        { value: 'Выдали ли Вам копии протоколов?', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' },
        { value: 'Что Вы указали в протоколе?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' },
        { value: 'Велась ли сотрудниками ДПС видеозапись?', answer: 5, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '5' },
        { value: 'Привлекались ли понятые?', answer: 6, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '6' },
        { value: 'Были ли свидетели управления Вами автомобилем?', answer: 7, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '7' },
        { value: 'Были ли пассажиры у Вас в авто?', answer: 8, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '8' },
        { value: 'Хочу узнать, в каких случаях назначат 1,5 или 2 года лишения', answer: 9, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '9' }
      ],
      questions1227: [
        { value: 'Был ли суд?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'Было ли ДТП?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'Являются ли повреждения автомобиля незначительными?', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' },
        { value: 'Скрывались ли с места ДТП?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' },
        { value: 'Имеются ли претензии у второго участника ДТП?', answer: 5, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '5' },
        { value: 'Что вы указали в протоколе и в письменных объяснениях?', answer: 6, valueAnswerTrue: 'Согласился', valueAnswerFalse: 'Не согласился', id: '6' },
        { value: 'Имеется ли в материалах дела видеозапись нарушения?', answer: 7, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '7' },
        { value: 'Привлекались ли свидетели?', answer: 8, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '8' },
        { value: 'Были ли пассажиры у Вас в авто?', answer: 9, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '9' },
        { value: 'Хочу узнать, как получить арест', answer: 10, valueAnswerTrue: 'Узнать', valueAnswerFalse: 'Спасибо, не нужно', id: '10' }
      ],
      questions128: [
        { value: 'Был ли суд?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'Находились ли вы в состоянии опьянения?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'Проходили ли Вы на месте освидетельствование или Вас доставили в наркологию?', answer: 3, valueAnswerTrue: 'Прошел на месте', valueAnswerFalse: 'Сразу в больницу', id: '3' },
        { value: 'Выдали ли Вам копии протоколов?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' },
        { value: 'Что Вы указали в протоколе?', answer: 5, valueAnswerTrue: 'Согласился', valueAnswerFalse: 'Не согласился', id: '5' },
        { value: 'Велась ли сотрудниками ДПС видеозапись?', answer: 6, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '6' },
        { value: 'Привлекались ли понятые?', answer: 7, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '7' },
        { value: 'Были ли свидетели управления Вами автомобилем?', answer: 8, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '8' },
        { value: 'Были ли пассажиры у Вас в авто?', answer: 9, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '9' },
        { value: 'Хочу узнать, в каких случаях назначат 1,5 или 2 года лишения', answer: 10, valueAnswerTrue: 'Узнать', valueAnswerFalse: 'Спасибо, не нужно', id: '10' }
      ],
      result: [],
      name: null,
      email: null,
      phone: null,
      isPopup: false,
      title: null,
      subtitle: null
    }
  },
  computed: {
    numArticle () {
      return this.$route.params.id
    }
  },
  created () {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  },
  methods: {
    removePopup () {
      this.isPopup = false
    },
    outMessUrist (e) {
      if (!this.name) {
        e.target.innerText = 'Вам необходимо указать как вас зовут'
        e.target.classList.add('error-send')
        this.isPopup = true
        this.title = 'Вам необходимо указать как вас зовут'
        this.subtitle = 'Пожалуйста, укажите Ваше имя. Это необходимо для более качественного предоставления услуг'
        setTimeout(() => {
          e.target.innerText = 'Узнать результаты'
          e.target.classList.remove('error-send')
        }, 800)
      } else if (!this.phone) {
        e.target.innerText = 'Вам необходимо указать email или телефон'
        e.target.classList.add('error-send')
        this.isPopup = true
        this.title = 'Вам необходимо указать Ваш номер телефона'
        this.subtitle = 'Пожалуйста, укажите Ваш телефон. Это необходимо для более качественного предоставления услуг. Ваши данные надежно защищены'
        setTimeout(() => {
          e.target.innerText = 'Узнать результаты'
          e.target.classList.remove('error-send')
        }, 800)
      } else {
        const vm = this
        vm.result.join()
        if (!this.name) e.target.innerText = 'Вам необходимо указать как вас зовут'
        else if (!this.phone && !this.email) e.target.innerText = 'Вам необходимо указать email или телефон'
        else {
          const reg = '^\\+\\d{1,3}\\s?\\(\\d{3}\\)\\s?\\d{3}(-\\d{2}){2}$' // для проверки правильности введенного номера
          if (this.phone.match(reg) !== null) {
            e.target.innerText = 'Ждите..'
            const formData = new FormData()
            formData.append('crm', '11')
            formData.append('pipe', '22')
            formData.append('contact[name]', this.name)
            formData.append('contact[199]', this.phone)
            formData.append('contact[200]', this.email)
            formData.append('lead[214]', 'free.avtourist.info')
            formData.append('note', 'Заявка с free.avtourist.info по статье ' + this.numArticle + '<br>' + this.result.join())

            axios.post('https://urist.v-avtoservice.com/api/import-lead',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
            ).then(res => {
              e.target.innerText = 'Заявка отправлена!'
              document.getElementById('modalSubscribe').style.display = 'block'
              document.getElementById('answer').style.display = 'block'
              this.$refs.form.remove()
            })
          } else {
            this.isPopup = true
            this.title = 'Номер не корректный!'
            this.subtitle = 'Пожалуйста, укажите правильно номер вашего телефона. Ваши данные надежно защищены.'
          }
        }
      }
    }
  },
  mounted () {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  }
}
</script>

<style lang="scss" scoped>
  .container {
    padding-top: 80px;
    background: url("../assets/img/rectal.png") center no-repeat,
      url("../assets/img/bg.jpg") center no-repeat;
    box-shadow: 0 0 10px rgba(0,0,0,.7);
    background-size: cover;
    color: #ffffff;
  }
  .v-services_articles {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    h2 {
      flex-basis: 100%;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
    }
    p {
      flex-basis: 100%;
      color: #ffffff;
    }
    .articles-container {
      cursor: pointer;
      width: 198px;
      height: 140px;
      margin: 5px;
      padding: 10px 0 0 10px;
      background: rgba(118,178,101,0.3);
      border: 1px solid rgba(32, 209, 82, 0.16);
      box-sizing: border-box;
      border-radius: 10px;
      position: relative;
      color: black;
      &::before {
        content: '';
        width: 25px;
        height: 15px;
        bottom: 10px;
        right: 10px;
        position: absolute;
        background: url("../assets/img/icons/arrow.svg") center no-repeat;
      }
      .title {
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #ffec00;
      }
      &:hover .title {
        color: #FFFFFF;
      }
      &.active {
        background: #C5B927;
        color: white;
        .title {
          color: white;
        }
      }
      &:hover {
        background: #C5B927;
        color: #FFFFFF;
      }
    }
  }
  .v-services_quest {
    margin-bottom: 45px;
    .v-services_quest-container {
      display: flex;
      align-items: center;
      padding: 2px 2px 2px 15px;
      margin: 10px 10px;
      height: 40px;
      border-radius: 3px;
      border: 1px solid #E0E0E0;
      span {
        max-width: 50%;
      }
      .answer-wrapper {
        margin-left: auto;
        label {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          min-width: 120px;
          width: 200px;
          height: 38px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FFFFFF;
          margin: 0 2px;
          border-radius: 2px;
          border: 1px solid #E0E0E0;
          transition-duration: .3s;
          cursor: pointer;
          color: #FFED00;
          &.active {
            color: #FFFFFF;
            background: #20D152;
          }
          &:active {
            transform: scale(0.8);
          }
        }
      }
    }
  }
  .v-services_form {
    .title {
      margin-bottom: 25px;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      p { color: white; }
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .form-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 24px;
        label {
          margin: 10px 5px;
          position: relative;
          max-width: 30%;
          min-width: 200px;
          width: 30%;
          &:focus {
            color: #0F8831;
          }
          input {
            width: 100%;
            border: 1px solid #20D152;
            box-sizing: border-box;
            border-radius: 6px;
            height: 48px;
            padding: 20px 14px;
            &:focus {
              color: #0F8831;
            }
          }
          span {
            position: absolute;
            top: -15px;
            left: 5px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
    button {
      display: block;
      width: 220px;
      height: 48px;
      background: #20D152;
      margin: 0 auto;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 16px;
    }
    p {
      text-align: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.36);
      margin-bottom: 0;
    }
  }
  .v-services_answer {
    margin-bottom: 50px;
    .answer-wrapper {
      padding: 15px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      border-radius: 16px;
      .answer-container {
        margin: 15px 0;
        background: #FFFFFF;
        padding: 15px;
        box-shadow: 0 0 10px 2px rgba(221, 221, 221, 1);
        box-sizing: border-box;
        border-radius: 8px;
        .title {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          text-transform: uppercase;
        }
      }
    }
  }
  @media (max-width: 1110px) {
    .v-services_quest {
      .v-services_quest-container {
        display: flex;
        flex-wrap: wrap;
        height: initial;
        padding: 10px;
        span {
          margin-bottom: 10px;
          max-width: initial;
        }
        .answer-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          label {
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    .v-services_articles {
      margin-bottom: 30px;
      h2 {
        font-size: 22px;
        line-height: 26px;
      }
      .articles-container {
        width: calc(100% - 30px);
        height: 110px;
        margin: 5px;
        .title {
          margin-bottom: 7px;
        }
      }
    }
    .v-services_form {
      .title {
      }
      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .form-wrapper {
          label {
            margin: 10px 5px;
            position: relative;
            max-width: initial;
            min-width: 200px;
            width: 100%;
          }
        }
      }
      button {
        display: block;
        width: 220px;
        height: 48px;
        background: #20D152;
        margin: 0 auto;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 16px;
      }
      p {
        text-align: center;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.36);
      }
    }
  }
</style>
