<template>
  <div id="app">
    <header-block></header-block>
    <router-view/>
    <footer-block></footer-block>
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style lang="scss">
  @import "./assets/style.css";
  #app {
    max-width: 100vw;
  }
</style>
