<template>
  <div class="hello">
    <div class="back-image">
      <img src="../assets/img/home/background-img.svg" alt="">
    </div>
    <div>
      <div class="hello-head">
        <div class="container">
          <div class="hello-head__title">
            ВЫ ЗДЕСЬ<br>
            ЧТОБЫ <span>ВЕРНУТЬ<br>
            ПРАВА</span>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="middle">
          <div class="middle__title">СМОТРИТЕ, ЧТО ВЫ МОЖЕТЕ ПОЛУЧИТЬ<br>
            <span style="color: red; margin: 25px 0">БЕСПЛАТНО</span><br>
            ОТ НЕКОММЕРЧЕСКОЙ ОРГАНИЗАЦИИ ПАУ</div>
          <div class="middle__wrap">
            <div class="middle__container">
              <div class="middle__container-title">Получить решения судов по аналогичным делам</div>
              <div class="middle__container-input-wrap">
                <input data-inputmask="'mask': '+9(999)999-99-99'" v-model="waitBtn.btn1.value.tel" placeholder="Телефон" name="tel" type="text">
                <input v-model="waitBtn.btn1.value.email" placeholder="E-mail" name="email" type="text">
                <div @click="send(waitBtn.btn1)" class="btn">Пришлите</div>
              </div>
            </div>
            <div class="middle__container">
              <div class="middle__container-title">Получить бесплатные ходатайства по возврату прав</div>
              <div class="middle__container-input-wrap">
                <input data-inputmask="'mask': '+9(999)999-99-99'" v-model="waitBtn.btn2.value.tel" placeholder="Телефон" name="tel" type="text">
                <input v-model="waitBtn.btn2.value.email" placeholder="E-mail" name="email" type="text">
                <div @click="send(waitBtn.btn2)" class="btn">Жду ходатайства</div>
              </div>
            </div>
            <div class="middle__container">
              <div class="middle__container-title">Хочу бесплатное видео по самостоятельному возврату прав с гарантией</div>
              <div class="middle__container-input-wrap">
                <input data-inputmask="'mask': '+9(999)999-99-99'" v-model="waitBtn.btn3.value.tel" placeholder="Телефон" name="tel" type="text">
                <input v-model="waitBtn.btn3.value.email" placeholder="E-mail" name="email" type="text">
                <div @click="send(waitBtn.btn3)" class="btn">Жду видео</div>
              </div>
            </div>
            <div class="middle__container">
              <div class="middle__container-title">Получить бесплатную консультацию юриста или адвоката по своей статье</div>
              <div class="middle__container-input-wrap">
                <input data-inputmask="'mask': '+9(999)999-99-99'" v-model="waitBtn.btn4.value.tel" placeholder="Телефон" name="tel" type="text">
                <input v-model="waitBtn.btn4.value.email" placeholder="E-mail" name="email" type="text">
                <div @click="send(waitBtn.btn4)" class="btn">Пришлите</div>
              </div>
            </div>
            <div class="middle__container">
              <div class="middle__container-title">Записаться на бесплатную часовую видеоконсультацию с юристом</div>
              <div class="middle__container-input-wrap">
                <input data-inputmask="'mask': '+9(999)999-99-99'" v-model="waitBtn.btn5.value.tel" placeholder="Телефон" name="tel" type="text">
                <input v-model="waitBtn.btn5.value.email" placeholder="E-mail" name="email" type="text">
                <div @click="send(waitBtn.btn5)" class="btn">Записаться</div>
              </div>
            </div>
            <div class="middle__container">
              <div class="middle__container-title"><span>ХОТИТЕ СРАЗУ ВСЕ ЧТО ВЫШЕ?</span></div>
              <div class="middle__container-input-wrap">
                <input data-inputmask="'mask': '+9(999)999-99-99'" v-model="waitBtn.btn6.value.tel" placeholder="Телефон" name="tel" type="text">
                <input v-model="waitBtn.btn6.value.email" placeholder="E-mail" name="email" type="text">
                <div @click="send(waitBtn.btn6)" class="btn">Пришлите</div>
              </div>
            </div>
          </div>
          <div class="middle__title">ХОЧУ ПРЯМО СЕЙЧАС УЗНАТЬ ШАНСЫ ПО СВОЕМУ ДЕЛУ
            <p>
              Ответьте на несколько вопросов<br>
              Искусственный интеллект сопоставить результаты с 6844 постановлениями и решениями в базе<br>
              Вы получите результат, основанный на судебной практике
            </p>
          </div>
        </div>
        <div class="hello-check">
          <div class="hello-check_what">
            <div id="ya1" class="hello-check_what-title">
              ЧТО ДАЕТ ПРОВЕРКА?
              <div class="hello-check_what-subtitle">
                <p><span><img src="../assets/img/home/clock.svg" alt=""></span>Узнаете есть ли смысл тратить время и силы чтобы вернуть права</p>
                <p><span><img src="../assets/img/home/sud.svg" alt=""></span>Получите конкретные выдержки из судебных решений по возврату прав
                  и по лишению прав!</p>
              </div>
            </div>
            <div class="hello-check_what-articles">
              <h2>КТО МОЖЕТ УЗНАТЬ СВОИ ШАНСЫ</h2>
              <router-link tag="div" :to="{name: 'services', params: {id: numArticle[0]}}" class="articles-container">
                <div class="title">{{numArticle[0]}}</div>
                <div class="subtitle">Отказ от медицинского освидетельствования</div>
              </router-link>
              <router-link tag="div" :to="{name: 'services', params: {id: numArticle[1]}}" class="articles-container">
                <div class="title">{{numArticle[1]}}</div>
                <div class="subtitle">Управление в состоянии опьянения</div>
              </router-link>
              <router-link tag="div" :to="{name: 'services', params: {id: numArticle[2]}}" class="articles-container">
                <div class="title">{{numArticle[2]}}</div>
                <div class="subtitle">Выезд на встречную полосу</div>
              </router-link>
              <router-link tag="div" :to="{name: 'services', params: {id: numArticle[3]}}" class="articles-container">
                <div class="title">{{numArticle[3]}}</div>
                <div class="subtitle">Скрытие с места ДТП</div>
              </router-link>
              <router-link tag="div" :to="{name: 'services', params: {id: numArticle[4]}}" class="articles-container">
                <div class="title">{{numArticle[4]}}</div>
                <div class="subtitle">Управление без номеров и скрытие номеров</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notice
      v-if="isPopup"
      @removePopup="removePopup"
      :isPopup="isPopup"
      :title="title"
      :subtitle="subtitle"
    />
  </div>
</template>

<script>
import notice from './notification'
import axios from 'axios'
import Inputmask from 'inputmask'
export default {
  name: 'HelloWorld',
  components: { notice },
  data () {
    return {
      numArticle: [
        '12.26', '12.8', '12.15.4(5)', '12.27', '12.2'
      ],
      waitBtn: {
        btn1: { name: 'Пришлите', title: 'Получить решения судов по аналогичным делам', value: { tel: null, email: null } },
        btn2: { name: 'Жду ходатайства', title: 'Получить бесплатные ходатайства по возврату прав', value: { tel: null, email: null } },
        btn3: { name: 'Жду видео', title: 'Хочу бесплатное видео по самостоятельному возврату прав с гарантией', value: { tel: null, email: null } },
        btn4: { name: 'Получить', title: 'Получить бесплатную консультацию юриста или адвоката по своей статье', value: { tel: null, email: null } },
        btn5: { name: 'Записаться', title: 'Записаться на бесплатную часовую видеоконсультацию с юристом', value: { tel: null, email: null } },
        btn6: { name: 'Нужно всё', title: 'ХОТИТЕ СРАЗУ ВСЕ ЧТО ВЫШЕ?', value: { tel: null, email: null } }
      },
      isPopup: false,
      title: null,
      subtitle: null
    }
  },
  created () {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  },
  mounted () {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  },
  methods: {
    removePopup () {
      this.isPopup = false
    },
    send (e) {
      console.log(Boolean(e.value.email))
      if (!e.value.email) {
        this.isPopup = true
        this.title = 'Вам необходимо указать email'
        this.subtitle = 'Пожалуйста, укажите Вашу почту. Это необходимо для более качественного предоставления услуг'
      } else if (!e.value.tel) {
        this.isPopup = true
        this.title = 'Вам необходимо указать Ваш номер телефона'
        this.subtitle = 'Пожалуйста, укажите Ваш телефон. Это необходимо для более качественного предоставления услуг. Ваши данные надежно защищены'
      } else {
        const reg = '^\\+\\d{1,3}\\s?\\(\\d{3}\\)\\s?\\d{3}(-\\d{2}){2}$' // для проверки правильности введенного номера
        if (e.value.tel.match(reg) !== null) {
          const formData = new FormData()
          formData.append('crm', '11')
          formData.append('pipe', '22')
          formData.append('contact[199]', e.value.tel)
          formData.append('contact[200]', e.value.email)
          formData.append('lead[214]', 'free.avtourist.info')
          formData.append('note', 'Заявка с free.avtourist.info по теме: ' + e.title)

          axios.post('https://urist.v-avtoservice.com/api/import-lead',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          ).then(res => {
            document.getElementById('modalSubscribe').style.display = 'block'
            document.getElementById('answer').style.display = 'block'
          })
        } else {
          this.isPopup = true
          this.title = 'Номер не корректный!'
          this.subtitle = 'Пожалуйста, укажите правильно номер вашего телефона. Ваши данные надежно защищены.'
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .hello {
    position: relative;
    .back-image {
      position: absolute;
      right: 0;
      top: 40%;
      z-index: 0;
    }
    .hello-head {
      padding-top: 80px;
      height: 45vh;
      background: url("../assets/img/home/bg.png") center no-repeat;
      background-size: cover;
      .hello-head__title {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-weight: 800;
        font-size: 48px;
        line-height: 65px;
        color: white;
        margin-top: 5%;
        @media (max-width: 550px) {
          font-size: 36px;
          line-height: initial;
        }
        @media (max-width: 420px) {
          font-size: 30px;
        }
        span {
          color: #FFED00;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .middle {
      .middle__title {
        margin-top: 80px;
        text-align: center;
        font-weight: 800;
        font-size: 36px;
        line-height: 49px;
        margin-bottom: 50px;
        @media (max-width: 550px) {
          font-size: 26px;
          line-height: 29px;
        }
        @media (max-width: 420px) {
          font-size: 20px;
          line-height: 22px;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }
      }
      .middle__wrap {
        display: flex;
        flex-wrap: wrap;
        .middle__container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          border-bottom: 1px solid #FFED00;
          padding-top: 12px;
          padding-bottom: 20px;
          transition: all 0.5s;
          z-index: 200;
          @media (max-width: 1200px) {
            flex-wrap: wrap;
          }
          .middle__container-title {
            @media (max-width: 1200px) {
              margin-bottom: 15px;
            }
            span {
              font-weight: bold;
              font-size: 20px;
              line-height: 27px;
            }
          }
          .middle__container-input-wrap {
            display: flex;
            @media (max-width: 1200px) {
              flex-direction: column;
              width: 100%;
            }
            input {
              background: #FFFFFF;
              border-radius: 6px;
              padding: 10px 6px;
              border: 1px solid rgba(0,0,0,0.3);
              margin-right: 10px;
              display: none;
              transition: all .3s;
              @media (max-width: 1200px) {
                height: 20px;
                margin-bottom: 10px;
                width: 100%;
              }
            }
            .btn {
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FFFFFF;
              border-radius: 6px;
              color: #FFED00;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 180px;
              min-height: 40px;
              z-index: 100;
              @media (max-width: 1200px) {
                height: 40px;
                margin: 0 auto;
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
          &:hover {
            input {
              display: inline-block;
            }
          }
        }
      }
    }
    .hello-check {
      padding: 45px 0 45px 45px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      border-radius: 16px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF;
      margin-top: 60px;
      .hello-check_what {
        .hello-check_what-title {
          font-weight: bold;
          font-size: 28px;
          line-height: 34px;
        }
        .hello-check_what-subtitle {
          display: flex;
          flex-wrap: wrap;
          justify-content: initial;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          & p:first-child {
            margin-right: 30px;
          }
          p {
            max-width: 460px;
            span {
              float: left;
              height: 100%;
            }
          }
        }
        .hello-check_what-articles {
          display: flex;
          flex-wrap: wrap;
          h2 {
            flex-basis: 100%;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
          }
          .articles-container {
            cursor: pointer;
            width: 198px;
            height: 140px;
            margin: 5px;
            padding: 10px 0 0 10px;
            background: #ffffff;
            border: 1px solid rgba(32, 209, 82, 0.16);
            box-sizing: border-box;
            border-radius: 10px;
            position: relative;
            &::before {
              content: '';
              width: 25px;
              height: 15px;
              bottom: 10px;
              right: 10px;
              position: absolute;
              background: url("../assets/img/icons/arrow.svg") center no-repeat;
            }
            .title {
              margin-bottom: 15px;
              font-weight: 600;
              font-size: 20px;
              line-height: 28px;
              color: #C5B927;
            }
            &:hover .title {
              color: #FFFFFF;
            }
            &:hover {
              background: #C5B927;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1080px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            h1 {
            }
            p {
              top: 270px;
            }
          }

          .subtitle {
            font-size: 13px;
            line-height: 16px;
          }
        }
        .hello-head_img {
          min-width: 300px;
          img {
            max-width: 90vw;
          }
          span {
            bottom: initial;
            top: 60%;
          }
        }
      }
    }
  }
  @media (max-width: 550px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            h1 {
              font-size: 24px;
              line-height: 28px;
            }
            p {
              top: 200px;
              font-size: 18px;
              line-height: 14px;
              margin-bottom: 20px;
            }
          }

          .subtitle {
            font-size: 13px;
            line-height: 16px;
          }
        }
        .hello-head_img {
          min-width: 100%;
          img {
            max-width: 90vw;
          }
          span {
            bottom: initial;
            top: 60%;
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            h1 {
              font-size: 24px;
              line-height: 28px;
            }
            p {
              font-size: 18px;
              line-height: 14px;
            }
          }
          .subtitle {
            font-size: 13px;
            line-height: 16px;
          }
        }
        .hello-head_img {
          max-width: 100%;
          img {
            max-width: 90vw;
          }
          span {
            width: 200px;
            height: 40px;
            font-size: 18px;
            top: 70%;
            left: -5px;
          }
        }
      }
      .hello-check {
        padding: 20px 0 45px 10px;
        .hello-check_what {
          display: flex;
          flex-direction: column;
          align-items: center;
          .hello-check_what-title {
          }
          .hello-check_what-subtitle {
            & p:first-child {
            }
            p {
              span {
              }
            }
          }
          .hello-check_what-articles {
            h2 {
            }
            .articles-container {
              width: calc(100% - 30px);
              height: 110px;
              .title {
              }
              &:hover .title {
              }
              &:hover {
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 330px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            p {
              top: 230px;
            }
          }
          .subtitle {
            font-size: 13px;
            line-height: 16px;
          }
        }
        .hello-head_img {
          max-width: 100%;
          img {
            max-width: 90vw;
          }
          span {
            width: 200px;
            height: 40px;
            font-size: 18px;
            top: 70%;
            left: -5px;
          }
        }
      }
      .hello-check {
        padding: 20px 0 45px 10px;
        .hello-check_what {
          display: flex;
          flex-direction: column;
          align-items: center;
          .hello-check_what-title {
          }
          .hello-check_what-subtitle {
            & p:first-child {
            }
            p {
              span {
              }
            }
          }
          .hello-check_what-articles {
            h2 {
            }
            .articles-container {
              width: calc(100% - 30px);
              height: 110px;
              .title {
              }
              &:hover .title {
              }
              &:hover {
              }
            }
          }
        }
      }
    }
  }
</style>
